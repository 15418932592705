import jwt_decode from "jwt-decode";
import { post } from "./api_actions";
import * as EndPoints from "./endpoints";



const isValid = (token) => {
    var decoded = jwt_decode(token) || {};
    
    if (!decoded.exp) return false;

    // make it invalid if it will expire at least 5 seconds from now
    return decoded.exp * 1000 - Date.now() > 5000;
  
}

const refreshJwt = async (jwt) => {
    const data = {
        "expiredToken": jwt.token,
        "refreshToken": jwt.refreshToken
      }

    const result = await post(EndPoints.toJwtRefresh(), data);
    if (result) {
        if (result.ok) {
            return result.json();
        }
    
        //result is not ok return the error
        const body = (await result.json()) || {};
        throw new Error(body.usrMsg || 'Unable to refresh JWT Token');
    } else {
        throw new Error("System error");
    }
}

const fetchJwt = async () => {
    const username = "khemebuen@gmail.com";
    const password = "dkdkdkdkoilsoadfasdfasd;aksdfasdfasdfasd";
    const appKey = "F3C8070C-2BB1-480A-8572-D4FE972CAF80";

    const data = {
                    username,
                    password,
                    appKey,
                };

    const result = await post(EndPoints.toJwt(), data);

    if (result) {
        if (result.ok) {
            return result.json();
        }
    
        //result is not ok return the error
        const body = (await result.json()) || {};
        throw new Error(body.usrMsg || 'Unable to fetch JWT Token');
    } else {
        throw new Error("System error");
    }
}

const getJwtToken = async () => {
    //Get token form local storage
    let data = localStorage.getItem("apiToken");
    if (!data) {
        data = await fetchJwt();
        localStorage.setItem("apiToken", JSON.stringify(data));
    } else {
        data = JSON.parse(data);
        if (!isValid(data.token)){
            data = await refreshJwt(data);
            localStorage.setItem("apiToken", JSON.stringify(data));
        }
    }
    return data.token;
}

export { getJwtToken }
const post = async (url, data, token) => {
    let headers =  {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    if (token){
        headers = {...headers, Authorization: `Bearer ${token}`,}
    }
    let result;

    try {
        result = await fetch(`${url}`, {
                                method: 'POST',
                                headers: headers,
                                body: JSON.stringify(data),
                        });
    } catch (e) {
        console.log(e);
    }
    //console.log(result);
    return result;
}

const get = async (url, token) => {
    let headers =  {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    if (token){
        headers = {...headers, Authorization: `Bearer ${token}`,}
    }
    let result;

    try {
        result = await fetch(`${url}`, {
                                method: 'GET',
                                headers: headers,
                        });
    } catch (e) {
        console.log(e);
    }
    //console.log(result);
    return result;
}

export { post, get }
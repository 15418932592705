const formatPhone = phone => {

    var phoneRegEx = new RegExp(/^(\d{3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  
    phone = phone.trim();
  
    var match = phoneRegEx.exec(phone);
  
    if (match) {
      var intlCode = '+';
      return [
        intlCode,
        match[1],
        ' ',
        match[2],
        ' ',
        match[3],
        ' ',
        match[4],
        ' ',
        match[5],
        ' ',
        match[6],
      ].join('');
    } else {
      return phone;
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "XAF",
      }).format(amount);
  }

  export { formatPhone, formatAmount }
  
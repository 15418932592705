import React from 'react';
import { Button } from 'reactstrap';
import * as Constants from "../constants";

const NextButton = ({pageNo, pageCount, onAction, enabled}) => {
    const isDisabled = !enabled();
    let btncolor = isDisabled ? "secondary" : "primary";
    if (!isDisabled) {
        btncolor = pageNo === Constants.CONFIRMATION_PAGE ? "success" : btncolor;
    }
    const btnLabel = pageNo === Constants.CONFIRMATION_PAGE ? "Process" : "Next";
    return (
        <div>    
            { 
                //pageNo < pageCount && 
            <Button  disabled={isDisabled} color={btncolor} onClick={()=>onAction()}>{btnLabel}</Button>
            }
        </div>
    );
}

export default NextButton;

import * as Constants from './constants';

export const providers = [
    {
        id:Constants.MTN,
        name:"MTN",
    },
    {
        id:Constants.ORANGE,
        name:"Orange",
    },
    {
        id:Constants.NEXTTEL,
        name:"Nexttel",
    },
    {
        id:Constants.YOOMEE,
        name:"Yoomee",
    },
]
import React from 'react';
import {Row, Col, Card, CardBody, CardFooter, CardTitle, Container} from 'reactstrap';

const ProviderPage = ({providers, onAction}) =>{
    return (
    <div>
        <Row>
            <Col className="col-sm-12 col-lg-12">
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <CardTitle className="text-center font-weight-bold">Select Your AirTime Provider</CardTitle>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Container className="providers">
                            <Row>
                                {
                                    providers.map((provider)=>{
                                        return (
                                                <Col key={provider.id} className="col-lg-2 col-md-4 col-6" onClick={()=>onAction(provider)}>
                                                    <img src={`assets/img/${provider.id}.jpeg`} className="img-fluid" alt="" />
                                                </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </div>
    )
}

export default ProviderPage;

import { post, get } from './api_actions';
import { getJwtToken } from "./jwt";
import * as EndPoints from "./endpoints";
import * as Constants from "../constants";

const cashOut = async (customerPhone, amount, customerEmail) => {
    
    const serviceId = process.env.REACT_APP_MOMO_CASHOUT;

    const token = await getJwtToken();
    
    const cashOutData = {
        amount: amount,
        customerPhonenumber: customerPhone,
        customerEmailaddress: customerEmail,
        serviceId: +serviceId
      }

    const result = await post(EndPoints.toCashOut(), cashOutData, token);
    if (result) {
        if (result.ok) {
            return result.json() || {};
          }
          const body = (await result.json()) || {};
          throw new Error(body.UsrMsg || 'Unable to perform cashout');
    }
}

const cashIn = async (customerPhone, amount, customerEmail) => {
    
    const serviceId = process.env.REACT_APP_MOMO_CASHIN;
    const token = await getJwtToken();
 
    const cashInData = {
        amount: amount,
        customerPhonenumber: customerPhone,
        customerEmailaddress: customerEmail,
        serviceId: +serviceId
      }

    const result = await post(EndPoints.toCashIn(), cashInData, token);
    if (result) {
        if (result.ok) {
            return result.json() || {};
          }
          const body = (await result.json()) || {};
          throw new Error(body.UsrMsg || 'Unable to perform cashIn');
    }
}

const topUp = async (customerPhone, amount, service, customerEmail) => {
    
    const topUpService = {
        [Constants.MTN]:process.env.REACT_APP_AIRTIME_MTN,
        [Constants.ORANGE]:process.env.REACT_APP_AIRTIME_ORANGE,
        [Constants.YOOMEE]:process.env.REACT_APP_AIRTIME_YOOMEE,
        [Constants.NEXTTEL]:process.env.REACT_APP_AIRTIME_NEXTTEL,
    }
    const serviceId = topUpService[service];

    const token = await getJwtToken();
 
    const topUpData = {
        amount: amount,
        customerPhonenumber: customerPhone,
        customerEmailaddress: customerEmail,
        serviceId: +serviceId
      }

    const result = await post(EndPoints.toTopUp(), topUpData, token);
    if (result) {
        if (result.ok) {
            return result.json() || {};
          }
          const body = (await result.json()) || {};
          throw new Error(body.UsrMsg || 'Unable to perform topup');
    }
}

const checkStatus = async(ptn) => {
    const token = await getJwtToken();

    const result = await get(EndPoints.toCheckStatus(ptn), token);
    if (result){
        if (result.ok) {
            return result.json() || {}
        }
        const body = (await result.json()) || {};
        throw new Error(body.UsrMsg || 'Unable to check status');
    }
}


export { cashOut, cashIn, topUp, checkStatus }
import React from 'react';
import {Row, Col, Card, CardBody, CardTitle, CardHeader, Label } from 'reactstrap';
import { formatPhone, formatAmount } from '../util/formatters';

const ConfirmationPage = ({data, onCancelRequest}) => {
    const sourcePhone = formatPhone(`237${data.source}`);
    const destinationPhone = formatPhone(`237${data.destination}`);
    const amount = formatAmount(data.amount);

    return  (<div>
    <Row>
        <Col className="col-sm-12 col-lg-12">
            <Card>
                <CardHeader>
                    <CardTitle className="text-center font-weight-bold">Confirmation</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col className="col-12">
                            <Label className="text-right font-weight-bold">AirTime Provider:</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pt-0">
                            <Label className="pl-5">{data.providerName}</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Label className="text-right font-weight-bold">Amount:</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pt-0">
                            <Label className="pl-5">{amount}</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Label className="text-right font-weight-bold">Recipient phone number ({`${data.providerName}`}):</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pt-0">
                            <Label className="pl-5">{destinationPhone}</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Label className="text-right font-weight-bold">Mobile Money Number:</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pt-0">
                            <Label className="pl-5">{sourcePhone}</Label>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    </Row>
    <Row className="pt-2">
        <Col className="col-sm-12 col-lg-12">
            <button type="button" className="btn btn-link btn-sm" onClick={()=>onCancelRequest()}>Cancel Request</button>
        </Col>
    </Row>
</div>
    )
}

export default ConfirmationPage;

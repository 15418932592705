export const NEXT_PAGE = "NEXT_PAGE";
export const PREV_PAGE = "PREV_PAGE";
export const SELECT_PROVIDER = "SELECT_PROVIDER";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const COMPLETE_REQUEST = "COMPLETE_REQUEST";
export const PROCESS_COMPLETED = "PROCESS_COMPLETED";
export const CASHOUT_RESULT = "CASHOUT_RESULT"; 
export const TOPUP_RESULT = "TOPUP_RESULT";

export const AIRTIME_AMOUNT = "amount";
export const DESTINATION = "destination";
export const SOURCE = "source";

export const MTN = "mtn";
export const ORANGE = "orange";
export const NEXTTEL = "nexttel";
export const YOOMEE = "yoomee";

export const PROVIDERS_PAGE = 1;
export const DETAILS_PAGE = 2;
export const CONFIRMATION_PAGE = 3;
export const PROCESSING_PAGE = 4;

export const API_SUCCESS = "SUCCESS";
export const API_PENDING = "PENDING";
export const API_ERRORED = "ERRORED";

export const CASH_OUT_STEP = "CASH_OUT_STEP";
export const AIRTIME_STEP = "AIRTIME_STEP";
export const CASH_IN_STEP = "CASH_IN_STEP";

export const TRANSACTION_SUCCESS = "success";
export const TRANSACTION_FAILURE = "failure";
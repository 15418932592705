import React from 'react';
import { FormGroup, Label, Input, InputGroupAddon, 
    InputGroupText, InputGroup} from 'reactstrap'
    import * as Constants from '../constants';

const AirTimeDetailsPage = ({provider, onChange, amount, source, destination, sourceError, destinationError}) => {
    
    return <div>
                <FormGroup>
                    <Label className="font-weight-bold" for="amount">Amount</Label>
                    <InputGroup>
                        <Input type="number" required className="text-right" name="amount" id="amount" 
                        onChange={(event)=> onChange(Constants.AIRTIME_AMOUNT, event.target.value)}
                        value={amount || ''}/>
                        <InputGroupAddon addonType="append">
                        <InputGroupText>XAF</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                    
                </FormGroup>
                <FormGroup>
                    <Label className="font-weight-bold" for="airTimePhone">Phone <span className="small text-muted font-weight-bold">({`${provider}`})</span></Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>+237</InputGroupText>
                        </InputGroupAddon>
                        <Input type="tel" autoComplete="tel" required name="airTimePhone" id="airTimePhone" onChange={(event) => onChange(Constants.DESTINATION, event.target.value)}
                          value={destination || ''}/>
                    </InputGroup>
                    {
                        destinationError && <div className="text-danger">{destinationError}</div>
                    }
                </FormGroup>
                <FormGroup>
                    <Label className="font-weight-bold" for="momoPhone">Mobile Money Phone <span className="small text-muted font-weight-bold">(Only MTN For Now)</span></Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>+237</InputGroupText>
                        </InputGroupAddon>
                        <Input type="tel" autoComplete="tel" required name="momoPhone" id="momoPhone" onChange={(event) => onChange(Constants.SOURCE, event.target.value)}
                             value={source || ''}/>
                    </InputGroup>
                    {sourceError && <div className="text-danger">{sourceError}</div>}
                </FormGroup>
    </div>
}

export default AirTimeDetailsPage;

import React, {Suspense} from 'react';
import ProcessWait from './ProcessWait';


const SuccessComponent = React.lazy(() => import('./Success'));
const FailedComponent = React.lazy(() => import('./Failed'));


const ProcessPage = ({data, message, status }) => {

    return <div>
        <ProcessWait message={message}/>
        {/*
        <Suspense fallback={<ProcessWait message={"Please Wait..."}/>}>
            {data.completionStatus && (data.completionStatus.result === 0) && <SuccessComponent message={data.completionStatus.message} />}
            {data.completionStatus && (data.completionStatus.result === 1) && <FailedComponent message={data.completionStatus.message} />}
        </Suspense>
        */}
    </div>
}

export default ProcessPage;
import React, {useReducer, useState, useEffect, useCallback} from 'react';
import * as Constants from "../constants";
import * as Vendors from "../providers";
import AirTimeDetailsPage from '../components/AirTimeDetailsPage';
import NextButton from '../components/NextButton';
import PreviousButton from '../components/PreviousButton';
import ConfirmationPage from '../components/ConfirmationPage';
import ProviderPage from '../components/ProviderPage';
import ProcessCompleteButton from '../components/ProcessCompleteButton';
import { Row, Col, Card, CardHeader, CardFooter, CardBody, Form } from 'reactstrap';
import ProcessPage from '../components/ProcessPage';
import { getJwtToken  } from "../api/jwt";
import { cashOut, cashIn, topUp, checkStatus } from "../api/airtime";
import { initialAirtimeInfo, airTimeInfoReducer } from "../reducers/airTimeReducer";

const initialCashOut = {cashoutStatus:'', receiptNumber: ''};

const MasterWizard = () => {
    
    const [info, dispatch] = useReducer(airTimeInfoReducer, initialAirtimeInfo);
    const [processMessage, setProcessMessage] = useState("Please Wait...");
    const [step, setStep] = useState();
    const [cashOutResult, setCashOutResult] = useState(initialCashOut);
    const [cashInResult, setCashInResult] = useState({});
    const [topUpResult, setTopUpResult] = useState({});
    const [transactionStatus, setTransactionStatus] = useState();

    const canMoveToPreviousPage = () => {
        return (info.pageNo > 1) && (info.pageNo !== Constants.PROCESSING_PAGE);
    }

    const canMoveToNextPage = () => {
        let result = false;
        switch(info.pageNo){
            case Constants.PROVIDERS_PAGE:
                result = (info.providerId) && (info.pageNo < info.pageCount);
                break;
            case Constants.DETAILS_PAGE:
                result = info.amount && info.source && info.destination;
                break;
            default:
                result = true;
        }

        if (result) {
            result = info.pageNo < info.pageCount;
        }
        return result;
    }
    const goToNextPage = ()=> {
        if (info.pageNo < info.pageCount) {
            const isProcess = (info.pageNo + 1) === Constants.PROCESSING_PAGE;
            dispatch({type:Constants.NEXT_PAGE});
            if (isProcess){
                setStep(Constants.CASH_OUT_STEP);
            }
        }
    }
    
    const goToPrevPage = ()=> {
        if (info.pageNo > 1){
            dispatch({type:Constants.PREV_PAGE});
        }
    }

    const selectProvider = (provider) => {
        const hadProvider = info.providerId;
        
        dispatch({type:Constants.SELECT_PROVIDER,payload:provider});
        if (!hadProvider)
            goToNextPage();
    }

    const changeAirtimeDetails = (field, value) => {
        dispatch({type:field,payload:value})
    }
    const resetState = () => {
        setStep('');
        setProcessMessage("Please Wait...");
        setCashOutResult(initialCashOut);
        setCashInResult({});
        setTopUpResult({});
        setTransactionStatus(null);
    }
    const cancelRequest = () => {
        dispatch({type:Constants.CANCEL_REQUEST})
        resetState();
    }

    const requestCompleted = () => {
        dispatch({type:Constants.COMPLETE_REQUEST});
        resetState();
    }

    //const processCompleted = (completionResult) => {
    //    dispatch({type:Constants.PROCESS_COMPLETED, payload:completionResult});
    //}


    const processTopUp = useCallback(()=>{
        
        setProcessMessage("Processing Airtime. Please Wait...");
        
        topUp(`237${info.destination}`, +info.amount, info.providerId, "example@example.com")
        .then(result => {
            setTopUpResult(result);

            if (result.status === Constants.API_SUCCESS) {
                setProcessMessage("The transaction was SUCCESSFULLY processed!");
                setStep('');
                dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:1, message:"The transaction was SUCCESSFULLY processed!"}});
                setTransactionStatus(Constants.TRANSACTION_SUCCESS);
            } else if (result.status === Constants.API_PENDING) {
                setProcessMessage(`The transaction ${result.ptn} is in ${result.status} status.`);
                dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:1, message:`Airtime ${result.ptn} is pending`}});
            } else {
                setStep(Constants.CASH_IN_STEP);
            }
        })
        .catch(error=>{
            const message = error.UsrMsg || 'Unable to proccess the payment (Cashout)';
            setProcessMessage(message);
            setStep(Constants.CASH_IN_STEP);
        });
    },[info.destination, info.amount, info.providerId])

    const processCashIn = useCallback(()=>{
        setProcessMessage("Transaction Failed, Processing Refund (Cash-in), Please wait...");

        cashIn(`237${info.source}`, +info.amount, "example@example.com")
        .then(result => {
            setStep('');
            setCashInResult({ptn:result.ptn, status:result.status, receiptNumber: result.receiptNumber});

            if (result.status === Constants.API_SUCCESS) {
                const msg = "Transaction failed. Your mobile money payment has been reimbursed. Please try again!";
                setProcessMessage(msg);
                setTransactionStatus(Constants.TRANSACTION_FAILURE);
                dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:1, message: msg}});
            }
            else if (result.status === Constants.API_PENDING) {
                const pendingMsg = `Transaction failed.  A refund of your payment is pending. The receipt number is ${result.receiptNumber}`;
                setProcessMessage(pendingMsg);
                dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:1, message:pendingMsg}});
            } else {
                const pendingMsg = `Transaction failed.  A refund of your payment had an error. The receipt number is ${result.receiptNumber}`;
                setProcessMessage(pendingMsg);
                dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, message:pendingMsg}});
            }
        })
        .catch(error=>{
            const message = error.UsrMsg || 'Transaction failed.  A refund of your payment had an error';
            setProcessMessage(message);
            dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, message}});
        });
    },[info.source, info.amount]);


    const processCashOut = useCallback(() => {
        setProcessMessage("Processing payment (Cash-out). Please wait...");

        cashOut(`237${info.source}`, +info.amount, "example@example.com")
        .then(result => {
            setCashOutResult({ptn:result.ptn, cashoutStatus:result.status, receiptNumber: result.receiptNumber});

            if (result.status === Constants.API_SUCCESS) {
                setStep(Constants.AIRTIME_STEP);
            }
            else if (result.status === Constants.API_PENDING) {
                setProcessMessage("A cash-out request has been sent to your Mobile Money number. The cash-out is free (NO additional fees shall be deducted from your account). Please dial *126# to confirm the transaction");
            } else {
                dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, message:"Cashout failed..."}});
            }
        })
        .catch(error=>{
            const message = error.UsrMsg || 'Unable to perform cashout';
            setProcessMessage(message);
            dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, message}});
        });
    },[info.source, info.amount]);

    const checkCashOutStatus = useCallback(()=>{
        
        console.log("cashout status check");
        const errorMsg = "The payment (Cashout) transaction failed. Please try again!";
        
        checkStatus(cashOutResult.ptn)
        .then((result) => {
            const cashOutSearch = result[0];
            setCashOutResult({ptn:cashOutSearch.ptn, cashoutStatus:cashOutSearch.status, receiptNumber: cashOutSearch.receiptNumber});
            
            //set the cashout status result
            if (cashOutSearch.status !== Constants.API_PENDING) {
                if (cashOutSearch.status === Constants.API_SUCCESS) {
                    setStep(Constants.AIRTIME_STEP);
                } else {
                    setProcessMessage(errorMsg);  
                    dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, message:errorMsg}});
                }
            }
        })
        .catch((error) => {
            const message = error.UsrMsg || errorMsg;
            //show this error to the screen
            setProcessMessage(message);
            dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, message}});
        })
    },[ cashOutResult.ptn])

    useEffect(()=>{
        getJwtToken().then(token => {
        });
    },[]);

    /*useEffect(()=> {
        let interval = null;
        
        if (cashInResult.status) {
            
            if (cashInResult.status === Constants.API_PENDING) {

            } else {
                clearInterval(interval);
                if (cashInResult.status === Constants.API_SUCCESS) {
                    
                } else {
                    const errorMsg = "Transaction failed. Your mobile money payment was unable to be reimbursed!";
                    setProcessMessage(errorMsg);
                    dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, errorMsg}});
                }
            }
        }
        
    },[cashInResult])*/

    useEffect(() => {
        
        let interval = null;

        if (cashOutResult.cashoutStatus) {
            
            if (cashOutResult.cashoutStatus === Constants.API_PENDING) {
                //Set message to the processing screen to let user know to accept the cashout

                interval = setInterval(()=> {
                    checkCashOutStatus();
                }, 5000);

            } else {
                console.log(`Cashout Status Changed from PENDING To ${cashOutResult.cashoutStatus}`);
                clearInterval(interval);

                if (cashOutResult.cashoutStatus === Constants.API_SUCCESS) {
                    setStep(Constants.AIRTIME_STEP);
                } else {
                    const errorMsg = "The payment (Cashout) transaction failed. Please try again!";
                    setProcessMessage(errorMsg);
                    dispatch({type:Constants.PROCESS_COMPLETED, payload:{result:0, errorMsg}});
                }
            }
        } 

        return () => {
            clearInterval(interval);
        }
        
    },[cashOutResult.cashoutStatus, checkCashOutStatus])    

    useEffect(() => {
        if (step) {
            if (step === Constants.CASH_OUT_STEP) {
                console.log("Processing CashOut");
                processCashOut();
            } else if (step === Constants.AIRTIME_STEP) {
                console.log("Processing TopUp");
                processTopUp();
            } else if (step === Constants.CASH_IN_STEP) {
                console.log("Processing CashIn");
                processCashIn();
            }
        }
    },[step, processCashOut, processTopUp, processCashIn])

    return <div>
        <Card>
            <CardHeader className="App-header">
                <img className="App-logo" src="assets/img/maviance_logo.jpeg" alt="" />
                <div>
                    <span className="font-weight-bold text-primary"> AirTime Purchase</span>
                </div>
                <div><span className="text-secondary text-muted">{`Page ${info.pageNo} of ${info.pageCount}`}</span></div>
            </CardHeader>
            <CardBody className="WizardContent">
            <Form>
                {info.pageNo === 1 && <ProviderPage providers={Vendors.providers} onAction={selectProvider}/>}
                {info.pageNo === 2 && <AirTimeDetailsPage provider={info.providerName} 
                                                          onChange={changeAirtimeDetails} 
                                                          amount={info.amount} 
                                                          source={info.source} 
                                                          destination={info.destination}
                                                          sourceError={info.sourceError} 
                                                          destinationError={info.destinationError}
                                                          />}
                {info.pageNo === 3 && <ConfirmationPage data={info} onCancelRequest={cancelRequest}/>}
                {info.pageNo === 4 && <ProcessPage data={info} 
                                                   message={processMessage} 
                                                   status={transactionStatus}
                                                   />}
            </Form>
            </CardBody>
            <CardFooter className="App-footer">
                { 
                    (!info.completionStatus) && 
                    <>
                        <Row>
                            <Col><PreviousButton pageNo={info.pageNo} onAction={goToPrevPage} enabled={canMoveToPreviousPage}/></Col>
                            <Col><NextButton pageNo={info.pageNo} pageCount={info.pageCount} onAction={goToNextPage} enabled={canMoveToNextPage}/></Col>
                        </Row>
                    </>
                }
                { 
                    (info.completionStatus) && 
                    <>
                        <Row>
                            <Col><ProcessCompleteButton onAction={requestCompleted}/></Col>
                        </Row>
                    </>
                }
            </CardFooter>
      </Card>
    </div>
}

export default MasterWizard;

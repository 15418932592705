
const xafMask = /^(6[5-9][0-9]{7})$/


  export const fixPhone = (phone = '') => {
    
    let onlyNumbers = phone.replace(/\D+/g, '');

    onlyNumbers = onlyNumbers.replace(/^011/, '');
    
    if (
          onlyNumbers.length === 8 &&
          !onlyNumbers.startsWith('6')
        ) {
          return `6${onlyNumbers}`;
        }
        return onlyNumbers;
  };
  
  export const validatePhone = (phone = '') => {
    const trimmed = fixPhone(phone);
    if (
      (trimmed.length === 0 && phone.length !== 0) ||
      (trimmed.length > 0 && !xafMask.test(trimmed))
    )
      return `Invalid phone number`;
    return '';
  };

  export const isMtn = (phone) => {
    const MTN_MASK = /^(23767[0-9]{7})$|^(237(650|651|652|653|654|680|681|682|683)[0-9]{6})$/;
  
    return MTN_MASK.test(phone);
  }
  
import React from 'react';
import { Button } from 'reactstrap';

const PreviousButton = ({pageNo, onAction, enabled}) =>{
    const isDisabled = !enabled();
    const btncolor = isDisabled ? "secondary" : "primary";
    return (
    <div>
        {
            //(pageNo > 1) && 
            <Button disabled={isDisabled} color={btncolor} onClick={()=>onAction()}>Prev</Button>
        }
    </div>
    )
}

export default PreviousButton;

import * as Constants from "../constants";
import { validatePhone, isMtn } from "../util/validators";

const initialAirtimeInfo = {
    pageNo: 1,
    pageCount: 4,
    sourceError:'',
    cashoutStatus:'',
    destinationError:'',
    providerId:'',
    providerName:''
}


const airTimeInfoReducer = (state, action) => {
    
    switch(action.type) {
        case Constants.NEXT_PAGE:
            return {...state, pageNo:state.pageNo + 1 }
        case Constants.PREV_PAGE:
            return {...state, pageNo:state.pageNo - 1 }
        case Constants.SELECT_PROVIDER:
            return {...state, 
                    providerId:action.payload.id,
                    providerName:action.payload.name }
        case Constants.AIRTIME_AMOUNT:
        case Constants.DESTINATION:
        case Constants.SOURCE:
            let value = action.payload;
            if (value && value.length === 0)
                value = null;

            let newData = {
                [action.type]:value
            };

            if (action.type !== Constants.AIRTIME_AMOUNT) {
                let error = validatePhone(value);
                if (!error && action.type === Constants.SOURCE)
                {
                    //Make sure the source is only MTN
                    if (!isMtn(`237${value}`)) {
                        error = 'Only MTN numbers are allowed';
                    }
                }
                newData = {...newData, [`${action.type}Error`]:error}
            }
            //return {...state, [action.type]:value }
            return {...state, ...newData }
        case Constants.PROCESS_COMPLETED:
            return { ...state, completionStatus:action.payload }
        case Constants.CASHOUT_RESULT:
            return {...state,
                       cashoutStatus:action.payload.status, 
                       cashoutPtn:action.payload.ptn
                    }
        case Constants.TOPUP_RESULT:
            return {...state,
                    topUpResult:action.payload}
        case Constants.CANCEL_REQUEST:
        case Constants.COMPLETE_REQUEST:
            return { ...initialAirtimeInfo }
        default:
            return state;
    }
}

export { airTimeInfoReducer, initialAirtimeInfo }
import React from 'react';
import { Button } from 'reactstrap';

const ProcessCompleteButton = ({ onAction }) =>{
    
    return (
    <div>
        {
            <Button  color={'primary'} onClick={()=>onAction()}>Done</Button>
        }
    </div>
    )
}

export default ProcessCompleteButton;


const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const toTopUp = () => {
    return `${baseUrl}/GMD/topup`;
}

export const toCashIn = () => {
    return `${baseUrl}/GMD/cashin`;
}

export const toCashOut = () => {
    return `${baseUrl}/GMD/cashout`;
}

export const toJwt = () => {
    return `${baseUrl}/Authentication/token`;
}

export const toJwtRefresh = () => {
    return `${baseUrl}/Authentication/refreshtoken`;
}

export const toCheckStatus = (ptn) => {
    return `${baseUrl}/GMD/transactions/search?ptn=${ptn}`;
}